import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
 
import { connect } from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { fetchClusters, getDevicesByProduct } from '../utils/Cachengo';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TemporaryDrawer from './TemporaryDrawer';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlbumIcon from '@material-ui/icons/Album';
import DeviceGeneralInfo from './DeviceGeneralInfo';
import { setDevicesToInstall } from '../actions/installation';

import { removeEventHandler, addEventHandler } from '../actions/items';



const mapStateToProps = (state) => {
    return {
      payInfo: state.payInfo || {},
    };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
  }
}

const useStyles = makeStyles((theme) => ({
  bread:{
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    resources: {
      padding: theme.spacing(1),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100vh',
    },
    header: {
      textAlign: 'center',
      justifyContent: 'center',
      // padding: theme.spacing(2),
      width: '100%',
      textAlign: 'left',
      background: '#eaeded',
      color: 'black',
      fontWeight: 'bold',
      border: '1.5px solid black'
    },
    card: {
      border: '1px solid #d5dbdb',
      // maxHeight: '4vh'
      // borderRadius: '1px',
      // borderWeight: 'light',
    },
  }
}));

function createData(id, name, cluster_id, servers, agents) {
  return {
    id,
    name,
    cluster_id,
    servers,
    agents,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'cluster_id',
    numeric: true,
    disablePadding: false,
    label: 'Cluster ID',
  },
  {
    id: 'servers',
    numeric: true,
    disablePadding: false,
    label: 'Servers',
  },
  {
    id: 'agents',
    numeric: true,
    disablePadding: false,
    label: 'Agents',
  },
];

function EnhancedTableHead(props) {

  const tableRef = useRef();

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all clusters',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const numSelected = props.numSelected;
//   const theme = createTheme({
//     palette: {
//         primary: {
//         light: '#fc5001',
//         main: '#fc5001',
//         dark: '#fc5001',
//         contrastText: '#fff',
//         },
//     },
//   });
//   let theme = createTheme()
  const goToNewCluster = () => {
    props.history.push('/new_cluster')
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{justifyContent:'space-between'}}
    >
      {numSelected > 0 ? (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
          <Tooltip title="Terminate Instance">
        <IconButton>
            <DeleteIcon />
        </IconButton>
        </Tooltip>
        </Typography>
        </div>
      ) : (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Clusters
          <Tooltip title="Filter list">
        <IconButton>
            <FilterListIcon />
        </IconButton>
        </Tooltip>
        </Typography>

        </div>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
        <Button
            style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'
        }}
            // color="primary"
            // color='white'
            variant="contained"
            // textColor="white"/
            // theme={theme}
            onClick={goToNewCluster}
        >
            Create Cluster
        </Button>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ConnectedEnhancedClusterTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([]);
  const [devices, setDevices] = useState([]);  

  const classes = useStyles();
  const testData = [
    {
        "name": "Rent-A-Node-3",
        "handle": "02b141ed-1e7e-4741-bfb6-860f6a8b3bb6",
        "mac": "00:16:3e:e3:19:1c",
        "private_ip": "192.168.3.36",
        "private_ipv6": "fdad:dcac:4e90:1:1:749a:c000:771",
        "status": "enabled",
        "arch": "aarch64",
        "ram": 16486182912,
        "cores": 1.0,
        "country": "United States",
        "province": "Tennessee",
        "city": "McKenzie",
        "is_vm": true,
        "version": "28c59dce2bfe64ddd1ffaee3709429d1cb0cbd82",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1717788517,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAy3n6EJxVnOfFwbg42ezw\n8z3LmMk\/VEZUI+0lxoqXEw46NIFmUUfiKe5eblcbzP2P2pFHmpyghdVdP+Hm\/CMl\n34rvFjapYVqto0zs+c+AdtXZFwV5mz91+OMJf2Dhvw29V4xqL\/sDj0TiHWjfOh2K\nWQ3Yf4pjVh98JFr2wPnOQFR2voNHQvIsltRJ8aLtLwDNqNVpx+SZT4tMnoCiBODb\nLiRY8TejGZayW9YD0WVB6GpnLVQzoXZkxldtKF34oLj72PaRGnekdoRdZfl3OQPo\n37Z7Hvq2u5vPpG89w3foQ1yU4DPBhlDGnQG0mNCyNPjeLGVYF2YfZYO5WY6GUjEy\n2QIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": true,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "ash_trinity",
        "handle": "f5df6eb9-818d-4598-adcd-89d0e15ac191",
        "mac": "3c:22:fb:80:9e:9b",
        "private_ip": "192.168.1.32",
        "private_ipv6": null,
        "status": "enabled",
        "arch": "x86_64\n",
        "ram": 68719476736,
        "cores": 8.0,
        "country": "United States",
        "province": "Tennessee",
        "city": "Huntingdon",
        "is_vm": false,
        "version": null,
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1626755325,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuIavjPq9oneDtydOBsTS\n5mEv0tC4w8VzSCprVbJcXg2Mg2AX7VU67J9Ued6v3gyV4ztcwuYxYdeCzCPs0fqI\nnY27UCOF\/uzqiq5GoMW71I95YjWWogq+9KyLYVtyUa5TxwXrEqHR26fSHvCsC8wm\nZdwqEnEBzmeAO86niE1PfR8\/DSUSiSGv7QAqUC3mKwkwJZzRG\/XaAnMxT+8k\/1P4\nTTsQAE7cEC4uqwiVvZbnmKSbBcFkh5gZj9kdfEKZN0oCA4wRngA7w\/mMnIdAEvXU\nv6waIAwsQJLXAiacde8sCmTBxdtASO22MI8X7gWVivnHsJypLDSZ7FFQiuj7KZFB\n6QIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": false,
        "sled_position": null,
        "supports_encryption": false,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "Brad-Ubuntu",
        "handle": "9ab8293d-0024-4647-a333-9529aca5bd5a",
        "mac": "c8:cb:9e:61:43:b5",
        "private_ip": "192.168.0.115",
        "private_ipv6": null,
        "status": "enabled",
        "arch": "x86_64",
        "ram": 67085705216,
        "cores": 1.0,
        "country": "United States",
        "province": "Tennessee",
        "city": "McKenzie",
        "is_vm": false,
        "version": "bb311c008d1fbe8a3f1366b44fa5fdd7b87c457a",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1717010099,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtkb7rG+z\/P2I7E1mJr7j\nVGLzdt\/shSVpq2ytlzBPuWLinoBBPCYMmNDPrk7jNfoRFYPuBSVG1KTkEB0ErNrK\nd0cXYDeeJ1E9s2br1pammksN1qygHZf4Q4ATOEylBNkFkucr45ByimyeoWkUNDEF\nsM2+DH17XehB1n0x1X7XexDc6xx4xxSJBWlTUN9k\/tzqgfFFBWOOGebQj6me8LoE\nqq55jTWp1j0BST0Fow82gl6r3y00jzWuWwU+Ihg9vxRWXwiJJwhw++gPBiWS4F\/C\ntjFMc8eJ741oVwC2BIYMW\/RRzWMSvAkt4slyTtAnyV2Cs6H925EO9uodjPEDLM6a\nJwIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": false,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "nebuchadnezzar",
        "handle": "04aab922-70f4-466c-9227-d83e7cb81046",
        "mac": "9c:76:0e:35:8e:36",
        "private_ip": "192.168.4.42",
        "private_ipv6": null,
        "status": "enabled",
        "arch": "x86_64\n",
        "ram": 34359738368,
        "cores": 10.0,
        "country": "United States",
        "province": "Georgia",
        "city": "Atlanta",
        "is_vm": false,
        "version": "bb311c008d1fbe8a3f1366b44fa5fdd7b87c457a",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1717788501,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA1KygJIsstjqUeKsf6mKF\nHiObT7u9LDiPY1YdS2\/jrBb6S\/1swJD27Fi5G5NJLXj894xXqbSW4gg+6dyealCV\naEP\/\/rf\/qPQkdqeowShSBP2yJ0BTpGZ2F\/+BzATn6MD8FE2UzZsx36HCsYDza7ZM\nAKxzs686BPpGF2nvdQdqMgjb59hpfH2pY2AsOjtLPSRM2fW1ARf55Xtkb7qlFHmY\nzTK+n4O5Q9tKcL7OtPhufN84InKn5nvTqdQU5OywdcDGTyJBr9ztGhwTmdUJeYGR\nXZdRtT4LCXapv4hqGjB6TWpqGpNJFBUAkuVB7ZYHHRtbwJyunm3pMEG74hUKiDfC\nFQIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": true,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "alien",
        "handle": "e3806ad7-c9b7-491a-9763-2ae2ccb9866a",
        "mac": "44:af:28:ba:a1:53",
        "private_ip": "192.168.1.79",
        "private_ipv6": null,
        "status": "enabled",
        "arch": "x86_64",
        "ram": 67081027584,
        "cores": 1.0,
        "country": "United States",
        "province": "Tennessee",
        "city": "McKenzie",
        "is_vm": false,
        "version": "bb311c008d1fbe8a3f1366b44fa5fdd7b87c457a",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1709652777,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyyKwTKH\/pxNiCankfxS6\nfCV7wydeNUIAgQ\/5E08mH65GVUYDvqLrx6zv\/xxOPxyWzC5YTWmgMW1KRM0Ef8hQ\neJD3KoTLtHHdGehWI+xO0g7SFw4DXROxxqjKoK4xYvUhD25+XMx2n+SBJo\/iUczU\nj+hQsMEutcWLs0xUUBc9DRG1T7xM9dN5khEK0Cj9asZcKf6o1wmEAflOS2yU7jlZ\nedVJt3SKaopYY4YBiU6wcWRW9THVdFnNp0YGJApF8QRvrxdlSFFZJSf30U\/DcEqv\nV05tMRAS8Q72tH7RErGpbqKdbpyYjGbbSnd+133TKZNzn3Qj49haBP+q115NhBQB\nAQIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": false,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "Knowdata",
        "handle": "6abcfeaa-8252-4df7-b824-05bf301fc711",
        "mac": "00:16:3e:fc:11:e8",
        "private_ip": "192.168.1.131",
        "private_ipv6": "fdad:dcac:4e90:1:1:749a:c000:788",
        "status": "enabled",
        "arch": "aarch64",
        "ram": 16486182912,
        "cores": 8.0,
        "country": "United States",
        "province": "Tennessee",
        "city": "McKenzie",
        "is_vm": true,
        "version": "23bc2cab97df4b0101ae06e645dbcceb7576e4b3",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1717788450,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtNluN9uDEQXklnAS6vFw\nVqCLaVdXjuie6ttmKa8CNQP\/bvgWXyYhSVIbPGlRvwAOHNv0HR5ZWtgC+y2HLkPM\n0KrDtSeCXXlS2R7+GlHOXVWG1zUNuJLgnNe8pQah83PqXCpE6ZHDojp7MSTg8Rwh\njMKIgT3AmbvC5gCOIaE77m2Jn+rGnvCJ1zNayyI3mwsBnN5\/XS16lD8T7UoG1cxu\nkJ5UDFyj1zP0+r+ysQWElsgbQemUhNOjN2Xd9wCvl0ghsYScv9wv3ieFvB55Y2m7\nZZXdrewaWkLMnQkttXMYbViONZszfmIR5RITCVZB6Px0z23WRyBCiBstsx06OX7I\n6QIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": true,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "Darwin's Mac",
        "handle": "c1ab3c1f-7d0f-4bf2-aac0-643cedf0f76f",
        "mac": "14:7d:da:99:a6:1d",
        "private_ip": "192.168.1.3",
        "private_ipv6": null,
        "status": "enabled",
        "arch": "x86_64\n",
        "ram": 8589934592,
        "cores": 2.0,
        "country": "United States",
        "province": "Tennessee",
        "city": "McKenzie",
        "is_vm": false,
        "version": "bb311c008d1fbe8a3f1366b44fa5fdd7b87c457a",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1692894075,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAr\/k84vMHV8ME7uio8iRq\nf1uYl8uuoF9ujkVe3\/Fy6urbrhsIX+YpIN+U1gznoCLOdQiasOd\/b25o1+BAnep9\nDK6o+rGsfdRpu4pnZEzbtDVgWtHeNDEFUsD2lJFNZgBqT5JuKVmeRBiHOF7PpVZx\nayeHSEYomebchCkBSTIyEggRK2mpPrpT3\/PVftCArgv6h9QzIBBjfNPVwwc1mHDi\n5WZ\/jfrohm79cgTNtteXWZTTIOcex5dqOMisZKT82UzM126qCQrW5m+jEs4TKMdI\nlYIV2tCkvUnNist+IfmtZPwG2XM84go4VaqbuskJFTNXyBHMtkxhI3ury3sfNu2l\nmwIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": false,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "Paul-Mac",
        "handle": "a323c568-98dc-4878-a254-6e96c38c19f8",
        "mac": "3c:15:c2:ca:05:2e",
        "private_ip": "192.168.1.6",
        "private_ipv6": "",
        "status": "enabled",
        "arch": "x86_64\n",
        "ram": 17179869184,
        "cores": 1.0,
        "country": "United States",
        "province": "California",
        "city": "San Leandro",
        "is_vm": false,
        "version": "23bc2cab97df4b0101ae06e645dbcceb7576e4b3",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1717704386,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsMKIAjfWvs7i8TGX307W\n45Opw5vK9SEDBEBOMyx6WNjjpSzHZ9m8AS8p4igTavkAgTaor3+WsjSZ8syHuMvo\nd5ao84NVF57OMvN2LvLQD\/hEAwQL5Vuz21nQNJtfjOeHC0Pw0dohmWSQ\/+TLe7JR\nDtVOt7ClDlx3R\/qOstyXydfjR5uU05I3iOe9Ph7qIIpjmWemMlfXNQuQswe\/W3IX\nh49+z8UMItQR5SgSujiwxI0rhGQ4Rv6b68ZZRY75mzaMqygUlbra1SX+nPa6O1HB\n0MpqOnSU+wQMevAzW9LJN1TYusyksQHtxR9ZU7d+CJ\/Gpmx8u0ECNK4HdJXBApDe\nzQIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": false,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "Rent-A-Node-4",
        "handle": "3b776e09-66bc-4435-bda1-53b24b5f8f20",
        "mac": "00:16:3e:a1:21:c5",
        "private_ip": "192.168.3.40",
        "private_ipv6": "fdad:dcac:4e90:1:1:749a:c000:779",
        "status": "enabled",
        "arch": "aarch64",
        "ram": 16486182912,
        "cores": 1.0,
        "country": "United States",
        "province": "Tennessee",
        "city": "McKenzie",
        "is_vm": true,
        "version": "28c59dce2bfe64ddd1ffaee3709429d1cb0cbd82",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1717788444,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwqX16Uv5cytplP8r7Kjj\nkxtw+c5lakjPl9PTyN3fARIikFtQKVC3j5IXEpoIhFYbATQXQNH2ftDOdIa9yoxL\njG1ck2sc0kcktEFY6aakuzAXjvxN0ERVTPaoFfZRxvzXPmhVHlmlW\/swvrrNFGsT\n4jZjtB+NLPPVeJvtgdP++cUIvP1ZIHkEPupVgqLvDJwssm+EoDvICSMa630NU23t\nM4PYpPu3d0KnjuVIKMqVM0QI8bGyybxruqHypsGVnEmiipOq5cnJfUtAZUAtemiw\na5QrDKzI8m0S2cgil6XtNK+UxY9AUdO8jgAsXn2lF6fidcpooHAab8aWrHvTQmZk\n7wIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": true,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "Neo",
        "handle": "d445aecf-8494-4d64-910e-ec7bcb9b876a",
        "mac": "5c:e9:1e:77:fb:07",
        "private_ip": "192.168.4.25",
        "private_ipv6": "",
        "status": "enabled",
        "arch": "x86_64\n",
        "ram": 103079215104,
        "cores": 1.0,
        "country": "United States",
        "province": "Georgia",
        "city": "Atlanta",
        "is_vm": false,
        "version": "23bc2cab97df4b0101ae06e645dbcceb7576e4b3",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1717788386,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAubsnRn5om16cjZjkjRjT\nRxs29yl75lsQj5jbWbW2lJVnnuLBiDJc5BBwlfRPIbgDW+3AxcAi5puUppBMyanI\nU1+73B\/T9iCofHpaI+eiDnEt8VpOkHYHjuXto1vayHLB5w\/Y9+utkWZ7BlQx+q1W\nArH\/8rn5IJelyliQvSnW4jnmPw47HXrG9sDbNvPoFDuw9BIMkF2CFnqwyfVJ\/Bib\nb74PNz8SHxX14Zjui1yTKn8jiEROnwDUukYfVOW4EkmJjXoYHtqkmjEnnEfzEv76\nHjqwTJgqXaCZx7tqtkyjq5Qk9ch3Rbs4YGBDsWKYGFcC0+\/hJOA6\/rYlww5Sfzi1\nqwIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": true,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "My-Rent-A-Node",
        "handle": "f8e19e92-1873-40e8-978f-9d0d82a72ef5",
        "mac": "00:16:3e:2a:61:04",
        "private_ip": "192.168.1.133",
        "private_ipv6": "fdad:dcac:4e90:1:1:749a:c000:783",
        "status": "enabled",
        "arch": "aarch64",
        "ram": 16486182912,
        "cores": 8.0,
        "country": "United States",
        "province": "Tennessee",
        "city": "McKenzie",
        "is_vm": true,
        "version": "23bc2cab97df4b0101ae06e645dbcceb7576e4b3",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1717788444,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA8nmai87L5TN45KeqdwtK\nAZhjMlxN3M5N1z9UacLLRaKZD4EC0pzoAdS2rbW6vsY4WOmab1CUgJrZOP\/Y7foI\nS66j2KC6XqfeuPCZUPOnjlYBm+kCHMRFf7piTaaFfmi6o0aZdMlsPgF7NSmekIe0\n+PKxlgYMOQpKhYODw4Z1CFMg2YYp8d2OSz3lzmli6Fb5U9yT6uSqItSizSdoduAJ\nm3J2kyO7b5mmdwYY8TNRMYAZivIzvlSJo1AJni9JDC9WBTzK9O6MGrk++ZWjqiu9\n9x2aVjpR7fKBZKwpcTXrvSjQFKZIW2tiQn5QJJyKBkGVK20x2+rgQWyuLdWvFPek\nywIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": true,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    },
    {
        "name": "Rent-A-Node-1",
        "handle": "7e24363a-d1dc-442f-b346-9f669d3554bf",
        "mac": "00:16:3e:9b:cc:b2",
        "private_ip": "192.168.1.103",
        "private_ipv6": "fdad:dcac:4e90:1:1:749a:c000:774",
        "status": "enabled",
        "arch": "aarch64",
        "ram": 16486182912,
        "cores": 8.0,
        "country": "United States",
        "province": "Tennessee",
        "city": "McKenzie",
        "is_vm": true,
        "version": "23bc2cab97df4b0101ae06e645dbcceb7576e4b3",
        "org": "0637e32a-76c1-4ad9-98df-c49ca9e05f77",
        "last_seen": 1717788401,
        "public_key": "-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzADV1yntEGKJ\/jDEYX\/j\nlVPsb9NSmVBcDjOTeG1gPEQG0QeaKh1QSyoGDvipqvzByLamH+RUuD5EuKRa0IFJ\nHLFMypMEnMVgURCH29S6Vb4oLoEZBZPEpOKf9GIS4llsVAhK7X0bZgnRoPyWrpjP\nmOjavV4Y2EBK8p6R2CNDdr96BB\/gWcT2FeIr5Hy5uNpMsPXeaDjRoIa0mNCkv4xM\nx3r7VePPDIUdTITuqCN2LzGJSo6qrQiBd1e9TWS2UZFmWtNyGxN4HsC65a\/YQNR2\niengMJqnIQRpP0pNGgDf975CjFRjvrTqmPdPhPtEA8x9U2erXkX\/MLY6KnhSQAdV\nlwIDAQAB\n-----END PUBLIC KEY-----\n",
        "is_connected": true,
        "sled_position": null,
        "supports_encryption": true,
        "sled_number": null,
        "product_type": ""
    }
]

  useEffect(() => {
    fetchClusters().then(res => {
      if (res && res.success) { 
        // const devices = res.devices.filter(device => device.is_vm === true);
        // const devices = testData.filter(device => device.is_vm === true);
        // setDevices(devices)
        // console.log(devices)
        let newRows = []
        res.clusters.forEach(function(cluster,i) {
          newRows.push(createData(i,cluster.name,cluster.cluster_id,res.servers[i],res.agents[i]))
        })
        console.log(newRows)
        setRows(newRows)
      } else {
        setRows([])
      }
    })
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const history = props.history;

  // const goToNewInstance = () => {
  //   history.push('/new_instance')
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  console.log('running')
  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );
  console.log(rows)
  console.log(visibleRows)
  console.log('test2')
  return (
    // <Box sx={{ width: '100%' }}>
    <div style={{height:'100vh'}}>

      <Grid container spacing={3}>
      <Grid item xs={12}>
      <div className={classes.bread}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" component="button" variant="body2" onClick={() => {props.history.push('/')}}>
            Home
          </Link>
          <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/rentnode')}}>
            Rent-a-Node
          </Link>
          <Typography color="textPrimary">Clusters</Typography>
        </Breadcrumbs>
      </div>
    <Paper sx={{ width: '100%', overflow: 'hidden', maxHeight: '35vh' }}>
        <EnhancedTableToolbar numSelected={selected.length} history={history}/>
        <TableContainer style={{ maxHeight: '35vh' }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.cluster_id}</TableCell>
                    {/* {
                        (row.instance_state === true)
                            ? <TableCell align="right" style={{color:'#1d8102'}}><CheckCircleOutlineIcon fontSize="small" style={{verticalAlign: 'bottom', color:'#1d8102'}}/>{"Running"}</TableCell>
                            : <TableCell align="right" style={{color:'red'}}><HighlightOffIcon fontSize="small" style={{verticalAlign: 'bottom', color:'red'}}/>{"Stopped"}</TableCell>
                    } */}
                    <TableCell align="right">{row.servers}</TableCell>
                    <TableCell align="right">{row.agents}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          on
        />
      </Paper>
      </Grid>
      {/* <Divider style={{backgroundColor:'black'}}></Divider> */}
      <Grid item xs={12} className={classes.resources}>
          <Paper className={classes.resources} style={{height:'100vh'}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
              { selected.length === 1 ?
                <CardHeader style={{borderBottom: '1px solid #d5dbdb'}} titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title={visibleRows.filter(device => device.id === selected[0])[0].name} className={classes.header}></CardHeader> :
                selected.length >= 1 ?
                <CardHeader titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title="Metrics" className={classes.header}></CardHeader> :
                <CardHeader titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title="Select a cluster" className={classes.header}></CardHeader>
              }
              </Grid>
              { selected.length === 1 ?
              <>
              <Grid item xs={12}>
                  <Accordion className={classes.heading} defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <AlbumIcon color="secondary" style={{marginRight: "5px"}}/> */}
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Instance Summary
                      </Typography>
                      {/* <CardHeader titleTypographyProps={{variant:'h6' }} xs={12} title="OS Image" className={classes.heading} ></CardHeader> */}

                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <DeviceGeneralInfo
                        // toggleEditInfoModal={toggleEditInfoModal}
                        name={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].name}
                        handle={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].handle}
                        last_seen={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].last_seen}
                        mac={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].mac}
                        private_ip={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].private_ip}
                        status={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].status}
                        is_vm={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].is_vm}
                        ram={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].ram}
                        cores={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].cores}
                        sled_position={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].sled_position}
                        sled_number={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].sled_number}
                        product_type={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].product_type}
                        version={devices.filter(device => device.handle === visibleRows.filter(dev => dev.id === selected[0])[0].instance_id)[0].version}
                      />
                      {/* <h3>Current image is Ubuntu 22.04. More images coming soon.</h3> */}
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <AlbumIcon color="secondary" style={{marginRight: "5px"}}/> */}
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Instance Details
                      </Typography>
                      {/* <CardHeader titleTypographyProps={{variant:'h6' }} xs={12} title="OS Image" className={classes.heading} ></CardHeader> */}

                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      {/* <DeviceGeneralInfo
                        toggleEditInfoModal={toggleEditInfoModal}
                        name={deviceInfo.name}
                        handle={deviceInfo.handle}
                        last_seen={deviceInfo.last_seen}
                        mac={deviceInfo.mac}
                        private_ip={deviceInfo.private_ip}
                        status={deviceInfo.status}
                        is_vm={deviceInfo.is_vm}
                        ram={deviceInfo.ram}
                        cores={deviceInfo.cores}
                        sled_position={deviceInfo.sled_position}
                        sled_number={deviceInfo.sled_number}
                        product_type={deviceInfo.product_type}
                        version={deviceInfo.version}
                      /> */}
                      <h3>Current image is Ubuntu 22.04. More images coming soon.</h3>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <AlbumIcon color="secondary" style={{marginRight: "5px"}}/> */}
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Peer Groups
                      </Typography>
                      {/* <CardHeader titleTypographyProps={{variant:'h6' }} xs={12} title="OS Image" className={classes.heading} ></CardHeader> */}

                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      {/* <DeviceGeneralInfo
                        toggleEditInfoModal={toggleEditInfoModal}
                        name={deviceInfo.name}
                        handle={deviceInfo.handle}
                        last_seen={deviceInfo.last_seen}
                        mac={deviceInfo.mac}
                        private_ip={deviceInfo.private_ip}
                        status={deviceInfo.status}
                        is_vm={deviceInfo.is_vm}
                        ram={deviceInfo.ram}
                        cores={deviceInfo.cores}
                        sled_position={deviceInfo.sled_position}
                        sled_number={deviceInfo.sled_number}
                        product_type={deviceInfo.product_type}
                        version={deviceInfo.version}
                      /> */}
                      <h3>Current image is Ubuntu 22.04. More images coming soon.</h3>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {/* <AlbumIcon color="secondary" style={{marginRight: "5px"}}/> */}
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Applications
                      </Typography>
                      {/* <CardHeader titleTypographyProps={{variant:'h6' }} xs={12} title="OS Image" className={classes.heading} ></CardHeader> */}

                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      {/* <DeviceGeneralInfo
                        toggleEditInfoModal={toggleEditInfoModal}
                        name={deviceInfo.name}
                        handle={deviceInfo.handle}
                        last_seen={deviceInfo.last_seen}
                        mac={deviceInfo.mac}
                        private_ip={deviceInfo.private_ip}
                        status={deviceInfo.status}
                        is_vm={deviceInfo.is_vm}
                        ram={deviceInfo.ram}
                        cores={deviceInfo.cores}
                        sled_position={deviceInfo.sled_position}
                        sled_number={deviceInfo.sled_number}
                        product_type={deviceInfo.product_type}
                        version={deviceInfo.version}
                      /> */}
                      <h3>Current image is Ubuntu 22.04. More images coming soon.</h3>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              </> : <></>}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
      {/* <TemporaryDrawer></TemporaryDrawer> */}
    </div>

    // </Box>
  );
}

const EnhancedClusterTable = connect(
    mapStateToProps, mapDispatchToProps
  )(ConnectedEnhancedClusterTable);
  
  export default EnhancedClusterTable;