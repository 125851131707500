import * as React from 'react';
import { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import EditIcon from "@material-ui/icons/Edit";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
 
import { connect } from "react-redux";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { getDevicesByProduct, getDevicesByProducts } from '../utils/Cachengo';
import ErrorIcon from '@material-ui/icons/Error';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TemporaryDrawer from './TemporaryDrawer';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AlbumIcon from '@material-ui/icons/Album';
import DeviceGeneralInfo from './DeviceGeneralInfo';
import { setDevicesToInstall, removeDevicesFromInstallation } from '../actions/installation';
import InstalledApps from './InstalledApps';
import InstalledSwitches from './InstalledSwitches';
import { getAllProducts, editProduct, getDeviceInfo, getAllInstallations, getOrgKeys } from "../utils/Cachengo";

import ProductEditModal from './modals/ProductEditModal';
import ProductGeneralInfo from './ProductGeneralInfo';
import { removeEventHandler, addEventHandler } from '../actions/items';
import AllInstalledApps from './AllInstalledApps';
import AllInstalledSwitches from './AllInstalledSwitches';
import AllInstalledKeys from './AllInstalledKeys';

import GetAppIcon from "@material-ui/icons/GetApp";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";

import {
  setConfirmationModalOpen,
  setSnackBarMessage,
} from "../actions/interactions";
import {
  removeMultipleDevices,
} from "../actions/installation";

import { updateDevice } from "../utils/Cachengo";
import MoveDevicesModal from './modals/MoveDeviceModal';
import SetInstallKeysModal from './modals/InstallKeyModal';
import CircularProgress from '@material-ui/core/CircularProgress';



const mapStateToProps = (state) => {
    return {
      payInfo: state.payInfo || {},
      item: state.itemToInstall,
      devices: state.devicesToInstall,
    };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
    setDevicesToInstall: (device) => dispatch(setDevicesToInstall(device)),
    removeDevicesFromInstallation: () => dispatch(removeDevicesFromInstallation()),
    openConfirmationModal: (item) => dispatch(setConfirmationModalOpen(item)),
    removeMultipleDevices: (handle) => dispatch(removeMultipleDevices(handle)),
    setSnackBarMessage: (message, type) => dispatch(setSnackBarMessage(message, type)),
    // setItemToInstall: (item) => dispatch(setItemToInstall(item)),
  }
}

const useStyles = makeStyles((theme) => ({
  bread:{
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    resources: {
      padding: theme.spacing(1),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100vh',
    },
    header: {
      textAlign: 'center',
      justifyContent: 'center',
      // padding: theme.spacing(2),
      width: '100%',
      textAlign: 'left',
      background: '#eaeded',
      color: 'black',
      fontWeight: 'bold',
      border: '1.5px solid black'
    },
    card: {
      border: '1px solid #d5dbdb',
      // maxHeight: '4vh'
      // borderRadius: '1px',
      // borderWeight: 'light',
    },
  }
}));

function createData(id, name, product_id, product_type, pod_id) {
  return {
    id,
    name,
    product_id,
    product_type,
    pod_id,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'product_id',
    numeric: true,
    disablePadding: false,
    label: 'Product ID',
  },
  {
    id: 'product_type',
    numeric: true,
    disablePadding: false,
    label: 'Product Type',
  },
  {
    id: 'pod_id',
    numeric: true,
    disablePadding: false,
    label: 'Pod ID',
  },
];

function EnhancedTableHead(props) {

  const tableRef = useRef();

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };


  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all products',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
 
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const numSelected = props.numSelected;

  const handleUpdateDevice = (rowData) => {
    let handles = rowData.map((device) => device.handle);
    return updateDevice(handles).then(() => {
      props.removeMultipleDevices(handles);
      props.setSelectedDevices([]);
      props.setSelected([]);
    });
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
      style={{justifyContent:'space-between'}}
    >
      {numSelected === 1 ? (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
          <Tooltip title="Edit Product Info">
            <IconButton
              onClick={() => {
                props.toggleEdit(props.selectedProducts[0]);
              }}
            >
                <EditIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title="Change Organization">
        <IconButton
          onClick={() => {
            props.toggleMoveDevicesModal(props.selectedDevices);
          }}
        >
            <SwapHorizIcon />
        </IconButton>
        </Tooltip>
        <Tooltip title="Update Symbiote(s)">
        <IconButton onClick={()=>{
            props.openConfirmationModal({
            show: true,
            message: "Are you sure you want to update the selected devices?",
            onYes: () => {
              handleUpdateDevice(props.selectedDevices).then(
                props.openConfirmationModal({
                  show: false,
                  message: null,
                  onYes: function () {},
                })
              );
            },
          });
        }}>
            <GetAppIcon />
        </IconButton>
        </Tooltip>
        </Typography>
        </div>
      ) : (
        <div>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Products
          <Tooltip title="Filter list">
        <IconButton>
            <FilterListIcon />
        </IconButton>
        </Tooltip>
        </Typography>

        </div>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
        {/* <Button
            style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'
        }}
            // color="primary"
            // color='white'
            variant="contained"
            // textColor="white"/
            // theme={theme}
            onClick={goToNewInstance}
        >
            Launch Instance
        </Button> */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function ConnectedEnhancedProductTable(props) {
  const tableRef = useRef();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([]);
  const [devices, setDevices] = useState({});  
  const [installedApps, setInstalledApps] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);        
  const [showEditModal, setShowEditModal] = useState(false);
  const [productEdit, setProductEdit] = useState({});
  const [products, setProducts] = useState(null);
  const [deviceInfo, setDeviceInfo] = React.useState([]);
  const [allInstallations,setInstallations] = React.useState([]);
  const [deviceInstallations, setDeviceInstallations] = useState({});
  const [deviceConnections, setDeviceConnections] = useState({});
  const [deviceKeys, setDeviceKeys] = useState({});
  const [isKeysModalOpen, setKeysModal] = useState(false)
  const [keys, setKeys] = useState([])
  const [isMoveDevicesModalOpen, setMoveDevicesModal] = React.useState(false);
  const [deviceHandles, setHandles] = useState([]);
  const [loadState, setLoadState] = useState(false);
  const [infoLoadState, setInfoLoadState] = React.useState(false);


  const classes = useStyles();

  useEffect(() => {
    props.addEventHandler("deviceTable", (message) => {
      if (
        message.event === "new_device" ||
        message.event === "remove_device" ||
        message.event === "edit_device" ||
        message.event === "connect_switch" ||
        message.event === "disconnect_switch" ||
        message.event === "edit_device_info" ||
        message.event === "update_install_state"
      ) {
        handleRefreshData();
      }
    });
    return () => props.removeEventHandler("deviceTable");
  }, [tableRef]);
  

  useEffect(() => {
    props.removeDevicesFromInstallation();
    handleRefreshData();
  }, [])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const clearSelectedItems = (handles) => {
    props.removeDevicesFromInstallation();
    setSelectedDevices([]);
    setSelected([]);
  };

  const handleRefreshData = ()=> {
    clearSelectedItems();
    getAllProducts({})
    .then(res => {
      setProducts(res.products)
      if (res.products.length > 0){
        const prod_handles = res.products.map((product) => product.handle)
        let newProducts = res.products
        getDevicesByProducts(prod_handles).then((res) => {
          if (res && res.success)
            {
              let newDevices = {}
              let newRows = []
              let devHandles = []

              res.products.forEach((product) => {
                newDevices[product.handle] = product.devices;
                devHandles = [...devHandles, ...product.devices.map(dev => dev.handle)];
              })

              newProducts.forEach((prod,i) => {
                newRows.push(createData(i,prod.name,prod.handle,prod.product_type,prod.pod))
              })

              setDevices(newDevices)
              setRowsPerPage(newRows.length)
              setRows(newRows)
              setLoadState(true)

            }
        })
      } else {
        setRowsPerPage(0)
        setRows([])
        setDevices({})
        setLoadState(true)
      }
    })
    refreshData()
  }

  const goToMarketplace = () => {
    props.history.push('/marketplace')
  }

  const goToSwitch = () => {
    props.history.push('/peergroups')
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    var newSelectedProds = []
    var newSelectedDevs = []

    newSelected.forEach((id) => {
      newSelectedProds.push(
        products.filter(
          product => product.handle === visibleRows.filter(
            prod => prod.id === id
          )[0].product_id
        )[0]
      )
      newSelectedDevs = [...newSelectedDevs,
        ...devices[
          products.filter(
            product => product.handle === visibleRows.filter(
              prod => prod.id === id
            )[0].product_id
          )[0].handle
        ]
      ]
    })

    let newDeviceInstallations = {}
    let newDeviceConnections = {}
    let newDeviceKeys = {}

    const devHandles = newSelectedDevs.map((dev)=>dev.handle)
    let devInstallations = []
    let devInfo = []

    setInfoLoadState(false)

    props.removeDevicesFromInstallation()
    props.setDevicesToInstall(newSelectedDevs)
    setSelectedProducts(newSelectedProds)
    setSelectedDevices(newSelectedDevs)
    setSelected(newSelected);

    if (devHandles.length === 0 || newSelectedProds.length > 1){
      props.removeDevicesFromInstallation()
      props.setDevicesToInstall(newSelectedDevs)
      setSelectedDevices(newSelectedDevs)
      setSelectedProducts(newSelectedProds)
      setSelected(newSelected); 
      setDeviceInstallations([])
      setDeviceConnections([])
      setDeviceKeys([])
      setDeviceInfo([])
      setInfoLoadState(true)
    } else{
      getDeviceInfo(devHandles).then(res => {
        setDeviceInfo(res.info)
        devInfo = res.info
      }).then( () =>
        {
          getAllInstallations(devHandles).then(res => {
            setDeviceInstallations(res.installations)
            devInstallations = res.installations
          }).then(() => {
            newSelectedDevs.forEach((device)=> {
              newDeviceInstallations[device.handle] = devInstallations.filter((app) => app.device === device.handle && app.state.toLowerCase() === "installed")
              newDeviceConnections[device.handle] = devInfo.filter((dev) => dev.handle === device.handle).map((info) => info.connections)
              newDeviceKeys[device.handle] = devInfo.filter((dev) => dev.handle === device.handle).map((info) => info.keys)
            })
            newDeviceInstallations = parseInstallations(newSelectedDevs.map((dev)=>dev.handle),newDeviceInstallations)
            newDeviceConnections = parseConnections(newSelectedDevs.map((dev)=>dev.handle),newDeviceConnections)
            newDeviceKeys = parseKeys(newSelectedDevs.map((dev)=>dev.handle),newDeviceKeys)
        
            setDeviceInstallations(newDeviceInstallations)
            setDeviceConnections(newDeviceConnections)
            setDeviceKeys(newDeviceKeys)
            setInfoLoadState(true)
        
          })
        }
      )
    }
  };

  const history = props.history;

  // const goToNewInstance = () => {
  //   history.push('/new_instance')
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable();
  };

  const toggleEdit = (rowData) => {
    if (showEditModal){
      setProductEdit({})
      setShowEditModal(false)
      return;
    }
    setProductEdit(rowData)
    setShowEditModal(true)
  }

  const handleSubmit = (handle,name,pod) => {
    const data = {
      handle: handle,
      product_name: name,
      pod_handle: pod,
    }
    editProduct(data).then(res => {
      if(res && res.success){
        handleRefreshData()
        setShowEditModal(false)
      }
    })
  }
  
  const toggleSetKeyModal = (rowData) => {
    if (isKeysModalOpen) {
      setKeysModal(!isKeysModalOpen);
    } else {
      getOrgKeys().then(res => {
        if (res && res.success) { 
          setKeys(res.public_keys);
          // const devices = testData.filter(device => device.is_vm === true);
            let devices = selectedDevices
            devices = devices.filter((device) => device.is_connected)
      
            let handles = devices.map((device) => device.handle);
      
            let numberNotEntered = selectedDevices.length - devices.length;
            if (numberNotEntered === selectedDevices.length) {
              let errMsg = "All devices are offline";
              props.setSnackBarMessage(errMsg, "error");
              return;
            }
      
            if (numberNotEntered > 0) {
              let msg1 = `${numberNotEntered} device is not online`;
              let msg2 = `${numberNotEntered} devices were not used because they are offline`;
              let message = numberNotEntered > 1 ? msg2 : msg1;
              props.setSnackBarMessage(message, "warning");
            }
            setKeysModal(!isKeysModalOpen);
            setHandles(handles);
        } else {
          let errMsg = "Cannot retrieve keys";
          props.setSnackBarMessage(errMsg, "error");
          return;
        }
      })
    }
  };


  // const toggleSetRentPriceModal = (rowData) => {
  //   if (isPriceModalOpen) {
  //     setPriceModal(!isPriceModalOpen);
  //   } else {
  //     let devices = rowData.filter((device) => !device.is_vm);
  //     devices = devices.filter((device) => device.is_connected)

  //     let handles = devices.map((device) => device.handle);

  //     let numberNotEntered = rowData.length - devices.length;
  //     if (numberNotEntered === rowData.length) {
  //       let errMsg = "All devices are VMs or Offline and cannot be set for rent";
  //       props.setSnackBarMessage(errMsg, "error");
  //       return;
  //     }

  //     if (numberNotEntered > 0) {
  //       let msg1 = `${numberNotEntered} device was not set for rent because it is a VM or offline`;
  //       let msg2 = `${numberNotEntered} devices were not set for rent because they are VMs or offline`;
  //       let message = numberNotEntered > 1 ? msg2 : msg1;
  //       props.setSnackBarMessage(message, "warning");
  //     }
  //     setPriceModal(!isPriceModalOpen);
  //     setHandles(handles);
  //   }
  // };

  const toggleMoveDevicesModal = (rowData) => {
    let devices = rowData;
    if (isMoveDevicesModalOpen == false) {
      let handles = devices.map((device) => device.handle);
      setHandles(handles);
      setMoveDevicesModal(true);
    } else {
      setMoveDevicesModal(false);
    }
  };

  const parseInstallations = (handles, installations) => {

    if (handles.length > 1) {
      let appsDict = {};

      for (let i = 0; i < handles.length; i++) {
        for (
          let n = 0;
          n < installations[handles[i]].length;
          n++
        ) {
          if (!appsDict[installations[handles[i]][n].name]) {
            appsDict[installations[handles[i]][n].name] = 1;
          } else {
            appsDict[installations[handles[i]][n].name]++;
          }
        }
      }     
      if (Object.keys(appsDict)) {
        for (let key in appsDict) {
          if (appsDict.hasOwnProperty(key)) {
            if (appsDict[key] != handles.length) {
             delete appsDict[key];
              }
          }
        }
        for (let n = 0; n < Object.keys(installations).length; n++) {
         installations[Object.keys(installations)[n]] = installations[Object.keys(installations)[n]].filter((app) => Object.keys(appsDict).includes(app.name))
        }
        return installations;
      }
    }
    return installations;
  }

  const parseKeys = (handles, keys) => {
    if (handles.length > 1) {
      let appsDict = {};

      for (let i = 0; i < handles.length; i++) {
        for (
          let n = 0;
          n < keys[handles[i]][0].length;
          n++
        ) {
          if (!appsDict[keys[handles[i]][0][n].name]) {
            appsDict[keys[handles[i]][0][n].name] = 1;
          } else {
            appsDict[keys[handles[i]][0][n].name]++;
          }
        }
      }
     
      if (Object.keys(appsDict)) {
        for (let key in appsDict) {
          if (appsDict.hasOwnProperty(key)) {
            if (appsDict[key] != handles.length) {
             delete appsDict[key];
              }
          }
        }
        for (let n = 0; n < Object.keys(keys).length; n++) {
         keys[Object.keys(keys)[n]][0] = keys[Object.keys(keys)[n]][0].filter((app) => Object.keys(appsDict).includes(app.name))
        }
        return keys;
      }
    }
    return keys;
  }

  const parseConnections = (handles, connections) => {

    if (handles.length > 1) {
      let switchDict = {};

      for (let i = 0; i < handles.length; i++) {
        for (
          let n = 0;
          n < connections[handles[i]][0].length;
          n++
        ) {
          if (!switchDict[connections[handles[i]][0][n].switch]) {
            switchDict[connections[handles[i]][0][n].switch] = 1;
          } else {
            switchDict[connections[handles[i]][0][n].switch]++;
          }
        }
      }

      if (Object.keys(switchDict)) {
        for (let key in switchDict) {
          if (switchDict.hasOwnProperty(key)) {
            if (switchDict[key] != handles.length) {
             delete switchDict[key];
              }
          }
        }
        for (let n = 0; n < Object.keys(connections).length; n++) {
         connections[Object.keys(connections)[n]][0] = connections[Object.keys(connections)[n]][0].filter((connection) => Object.keys(switchDict).includes(connection.switch))
        }
        return connections;
      }
    }
    return connections;
  }

  return (
    // <Box sx={{ width: '100%' }}>
    <div style={{height:'100vh'}}>

      <Grid container spacing={3}>
      <Grid item xs={12}>
      <div className={classes.bread}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" component="button" variant="body2" onClick={() => {props.history.push('/')}}>
            Home
          </Link>
          {/* <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/')}}>
            Knowhere
          </Link> */}
          <Typography color="textPrimary">Products</Typography>
        </Breadcrumbs>
      </div>
    <Paper sx={{ width: '100%', overflow: 'hidden', maxHeight: '35vh' }}>
        <EnhancedTableToolbar 
        numSelected={selected.length} history={history} toggleEdit={toggleEdit} 
        selectedProducts={selectedProducts} selectedDevices={selectedDevices} selected={selected} 
        openConfirmationModal={props.openConfirmationModal} 
        setSelectedDevices={setSelectedDevices} setSelected={setSelected} removeMultipleDevices={props.removeMultipleDevices}
        toggleMoveDevicesModal={toggleMoveDevicesModal}
        />
        <TableContainer style={{ maxHeight: '35vh' }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
          {loadState === true ?
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.product_id}</TableCell>
                    {/* {
                        (row.instance_state === true)
                            ? <TableCell align="right" style={{color:'#1d8102'}}><CheckCircleOutlineIcon fontSize="small" style={{verticalAlign: 'bottom', color:'#1d8102'}}/>{"Running"}</TableCell>
                            : <TableCell align="right" style={{color:'red'}}><HighlightOffIcon fontSize="small" style={{verticalAlign: 'bottom', color:'red'}}/>{"Stopped"}</TableCell>
                    } */}
                    <TableCell align="right">{row.product_type}</TableCell>
                    <TableCell align="right">{row.pod_id}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            :
            <TableBody>
              <Box sx={{ display: 'flex' }}><CircularProgress/></Box>
            </TableBody>
            }
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={-1}
          component="div"
          count={rows.length}
          rowsPerPage={rows.length}
          page={page}
          // onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          on
        />
      </Paper>
      </Grid>
      {/* <Divider style={{backgroundColor:'black'}}></Divider> */}
      <Grid item xs={12} className={classes.resources}>
          <Paper className={classes.resources} style={{height:'100vh'}}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
              { selected.length === 1 ?
                <CardHeader style={{borderBottom: '1px solid #d5dbdb'}} titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title={visibleRows.filter(device => device.id === selected[0])[0].name} className={classes.header}></CardHeader> :
                <CardHeader titleTypographyProps={{variant:'h6', fontWeight:'bold' }} xs={12} title="Select a product" className={classes.header}></CardHeader>
              }
              </Grid>
              { selected.length === 1 && infoLoadState ?
              <>
              <Grid item xs={12}>
                  <Accordion className={classes.heading} defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Selected Devices
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <ProductGeneralInfo
                        devices={selectedDevices}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Peer Groups
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              // color="primary"
                              // color='white'
                              variant="contained"
                              // textColor="white"/
                              // theme={theme}
                              onClick={goToSwitch}
                          >
                              Join Peer Group
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <AllInstalledSwitches
                            connections={deviceConnections}
                            handles={selectedDevices.map((device) => device.handle)}
                            clearSelectedItems={clearSelectedItems}
                            handleOpenConfirmation={props.openConfirmationModal}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Applications
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              variant="contained"
                              onClick={goToMarketplace}
                          >
                              Install Application
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <AllInstalledApps
                            apps={deviceInstallations}
                            clearSelectedItems={clearSelectedItems}
                            handleOpenConfirmation={props.openConfirmationModal}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              <Grid item xs={12}>
                  <Accordion className={classes.heading}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading} fontWeight="fontWeightBold">
                        Public Keys
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{background:'white'}}>
                      <Grid container spacing={3}>
                      <Grid item xs={12}>
                          <Button
                              style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'}}
                              variant="contained"
                              onClick={toggleSetKeyModal}
                          >
                              Install Public Key
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <AllInstalledKeys
                            keys={deviceKeys}
                            handles={selectedDevices.map((device) => device.handle)}
                            clearSelectedItems={clearSelectedItems}
                            handleOpenConfirmation={props.openConfirmationModal}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
              </Grid>
              </>   
              : selected.length === 1 && !infoLoadState ?
              <Box sx={{ display: 'flex' }}><CircularProgress/></Box>
              : 
              <></>}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
        </Grid>
      </Grid>
      <ProductEditModal
          product={productEdit}
          show={showEditModal}
          handleClose={toggleEdit}
          handleSubmit={handleSubmit}
        />
     <MoveDevicesModal
        show={isMoveDevicesModalOpen}
        handleClose={toggleMoveDevicesModal}
        handles={deviceHandles}
        refreshData={refreshData}
        handleOpenConfirmation={props.openConfirmationModal}
        clearSelectedItems={clearSelectedItems}
      />
      <SetInstallKeysModal
         show={isKeysModalOpen}
         handleClose={toggleSetKeyModal}
         handles={deviceHandles}
         keys={keys}
         clearSelectedItems={clearSelectedItems}
         handleOpenConfirmation={props.openConfirmationModal}
       />
    </div>

    // </Box>
  );
}

const EnhancedProductTable = connect(
    mapStateToProps, mapDispatchToProps
  )(ConnectedEnhancedProductTable);
  
  export default EnhancedProductTable;