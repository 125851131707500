import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import { getAllProducts } from "../utils/Cachengo";
import CircularProgress from '@material-ui/core/CircularProgress';

function RedirectDashboard() {
  const [products, setProducts] = useState(null)
  const history = useHistory()

  useEffect(() => {
    getAllProducts({})
    .then(res => {
      console.log(res)
      setProducts(res.products || [])
    })
  }, [])

  if (products != null) {
    if (products.length == 0) {
      history.push('/rentnode')
    } else {
      history.push('/home')
    }
  }

  const divStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh"
  }
  
  return (
    <div style={divStyle}>
      <CircularProgress color="secondary" size={80} thickness={2}/>
    </div>
  )
}

export default RedirectDashboard