/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ApplicationTable from './ApplicationTable';
import SwitchTable from './SwitchTable';
import DeviceTable from './DeviceTable';
import RentNodeTable from './RentNodeTable';
import EnhancedKeyTable from './KeyTable';
import OrgManager from './OrgManager';
import PaymentsManager from './PaymentsManager';
import InstallationCard from './InstallationCard';
import DeviceInfo from './DeviceInfo';
import MarketPlace from './MarketPlace'
import { Route } from "react-router-dom";
import Toolbar from '@material-ui/core/Toolbar';
import NewApp from './NewApp';
import EditApp from './EditApp';
import Profile from './Profile';
import SwitchInfo from './SwitchInfo';
import ViewLog from './ViewLog';
import ReadmeViewer from './ReadmeViewer';
import instructionsCreateApp from './instructionsCreateApp';
import MySales from './MySales';
import Terminal from './Terminal';
import EnhancedRentNodeTable from './InstanceTable';
import RentDashboard from './RentDashboard';
import EnhancedClusterTable from './ClusterTable';
import NewInstance from './NewInstance';
import NewCluster from './NewCluster';
import EnhancedUserDeviceTable from './userDeviceTable';
import KnowhereDashboard from './KnowhereDashboard';
import EnhancedProductTable from './ProductTable';
import EnhancedSymbioteTable from './SymbioteTable';
import EnhancedKnowhereUserDeviceTable from './knowhereUserTable';
import EnhancedKnowhereKeyTable from './KnowhereKeyTable';
import RedirectDashboard from './RedirectDashboard';


const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#FFFFFF'
  },
}));

function Routes() {
  const classes = useStyles();
  return (
    <main className={classes.content}>
      <Toolbar />
      <Route exact path="/" component={RedirectDashboard} />
      <Route exact path="/home" component={KnowhereDashboard} />
      <Route exact path="/profile" component={Profile} />
      <Route path="/applications" component={ApplicationTable} />
      <Route path="/new_app" component={NewApp} />
      <Route path="/edit_app/:handle" component={EditApp} />
      <Route path="/peergroups" component={SwitchTable} />
      <Route path="/peergroup/:id" component={SwitchInfo} />
      <Route path="/installations" component={InstallationCard} />
      <Route path="/marketplace" component={MarketPlace} />
      {/* <Route path="/rentnode" component={RentNodeTable} /> */}
      {/* <Route path="/rentnode" component={EnhancedRentNodeTable} /> */}
      <Route path="/rentnode" component={RentDashboard} />
      <Route path="/user_devices" component={EnhancedUserDeviceTable} />
      <Route path="/my_devices" component={EnhancedKnowhereUserDeviceTable} />
      <Route path="/new_instance" component={NewInstance} />
      <Route path="/new_cluster" component={NewCluster} />
      <Route path="/my_rentals" component={EnhancedRentNodeTable} />
      <Route path="/my_products" component={EnhancedProductTable} />
      <Route path="/my_symbiotes" component={EnhancedSymbioteTable} />
      <Route path="/my_keys" component={EnhancedKeyTable} />
      <Route path="/pub_keys" component={EnhancedKnowhereKeyTable} />
      <Route path="/my_clusters" component={EnhancedClusterTable} />
      <Route path="/orgmanager" component={OrgManager} />
      <Route path="/balances" component={PaymentsManager} />
      <Route path="/deviceinfo/:id" component={DeviceInfo} />
      <Route path="/view_log/:install_type/:id" component={ViewLog} />
      <Route path="/get_readme/:handle" component={ReadmeViewer} />
      <Route path="/instructions_create_app" component={instructionsCreateApp} />
      <Route path="/my_sales" component={MySales} />
      <Route path="/terminal/:name/:id" component={Terminal} />
    </main>
  )
}

export default Routes;
