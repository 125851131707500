/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

 import React, { useState } from 'react';
 import { useHistory } from "react-router-dom";
 import IconButton from '@material-ui/core/IconButton';
 import List from '@material-ui/core/List';
 import ListItem from '@material-ui/core/ListItem';
 import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
 import ListItemText from '@material-ui/core/ListItemText';
 import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
 import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
 import VisibilityIcon from '@material-ui/icons/Visibility';
 import DescriptionIcon from '@material-ui/icons/Description';
 import Box from '@material-ui/core/Box';
 import Grid from '@material-ui/core/Grid';
 import Divider from '@material-ui/core/Divider';
 import Tooltip from "@material-ui/core/Tooltip";
 import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
 import Typography from '@material-ui/core/Typography';
 import ListItemAvatar from "@material-ui/core/ListItemAvatar";
 import Avatar from "@material-ui/core/Avatar";
 import DeleteIcon from "@material-ui/icons/Delete";
 import FolderIcon from "@material-ui/icons/Folder";


 
 import ShowSecrets from './ShowSecrets';
 import {removeInstall, uninstallApp } from '../utils/Cachengo'
 
 
 function AllInstalledApps(props) {
   const [showSecrets, setShowSecrets] = useState([]);
   const history = useHistory()
 
   const uninstall = (handle) => {
     uninstallApp(handle)
   }
 
   const removeInstallation = (handle) => {
     removeInstall(handle)
   }

   const uninstallApps = (apps, name) => {
    for (let i = 0; i < Object.keys(apps).length; i++) {
      apps[Object.keys(apps)[i]].forEach((app) => {
        if (app.name == name) {
          uninstallApp(app.handle);
        }
      });
    }
  };
 
   const showSecretsComponent = (key) => {
     let showSecret = [...showSecrets];
     showSecret[key] = !showSecret[key]
     setShowSecrets(showSecret)
   }
 
   const viewLog = (install_type, handle) => {
     let idUrl = `/view_log/${install_type}/${handle}`
     history.push(idUrl);
   }

   const handleOpenConfirmationModal = (apps, app) => {
    props.handleOpenConfirmation({
      show: true,
      message:
        "Are you sure you want to permanently delete the selected app from all devices?",
      onYes: () => {
        uninstallApps(apps, app);

        props.handleOpenConfirmation({
          show: false,
          message: null,
          onYes: function () {},
        });
        props.clearSelectedItems();
      },
    });
  };

   const generate = (apps) => {
    const elements = [];
    const appNames = [];
    if (Object.keys(apps).length > 0) {
      apps[Object.keys(apps)[0]].forEach((app) => {
        appNames.push(app.name);
      });
    }
    appNames.forEach((app) => {
      elements.push(
        <ListItem key={app}>
          <ListItemAvatar>
            <Avatar>
              <FolderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={app} />
          <Tooltip title="Uninstall App">
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => handleOpenConfirmationModal(apps, app)}
          >
            <DeleteIcon />
          </IconButton>
          </Tooltip>
        </ListItem>
      );
    });

    return elements;
  };
 
   var apps = props.apps;
 
   return (
     <Grid container spacing={4} style={{margin: '10px', padding: '10px'}}>
       <Grid item xs={12} lg={6}>
         <Box variant="outlined">
           {(apps[Object.keys(apps)[0]].length != 0) ?
           <List>{generate(apps)}</List>
           :
           <Typography>No applications in common installed on these devices.</Typography>
           }
         </Box>
       </Grid>
       <Divider />
     </Grid>
   );
 }
 
export default AllInstalledApps;
