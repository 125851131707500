/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import SledIcon from "./SledIcon";

function ProductGeneralInfo(props) {

  const devices = props.devices.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    
  return (
    <Grid container>
      { devices.map((device) => {
        return (
          <Grid item xs={12} md={6} lg={1}>
            <ListItemText primary={device.name} />
          </Grid>
        )

      }
      )}
      {/* <Grid item xs={12} md={6} lg={4}>
        <ListItemText secondary={props.name} primary="Name" />
      </Grid> */}
    </Grid>
  );
}

export default ProductGeneralInfo;
