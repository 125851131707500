/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import { FULL_UPDATE, ADD_DEVICE_TO_INSTALL, REMOVE_DEVICE_TO_INSTALL, REMOVE_MULTIPLE_DEVICES_FROM_INSTALL } from "../constants/action-types";
import { setSnackBarMessage } from "./interactions";
import { setAppInstallModalOpen } from "./interactions";
import { postJSON, checkErrInRes } from '../utils/requests'
import { CACHENGO_APPS_URL } from "../utils/Constants";


export function setItemToInstall(payload) {
  return { 
    type: FULL_UPDATE, 
    payload: {itemToInstall: payload} 
  };
}

export function setDevicesToInstall(payload) {
  return { 
    type: ADD_DEVICE_TO_INSTALL, 
    payload: {devicesToInstall: payload} 
  };
}

export function removeItemFromInstallation() {
  return {
    type: FULL_UPDATE,
    payload: {
      itemToInstall: {
        name: "No Item Selected", 
        handle: null
      }
    }
  }
}

export function setAllDevicesToInstallation(payload) {
  return {
    type: FULL_UPDATE,
    payload: {
      devicesToInstall: payload
    }
  }
}

export function removeDevicesFromInstallation() {
  return {
    type: FULL_UPDATE,
    payload: {
      devicesToInstall: []
    }
  }
}

export function removeMultipleDevices(handles) {
  return {
    type: REMOVE_MULTIPLE_DEVICES_FROM_INSTALL,
    payload: {
      devicesToRemove: handles
    }
  }
}

export function removeSingleDevice(handle) {
  return {
    type: REMOVE_DEVICE_TO_INSTALL,
    payload: {
      deviceToRemove: handle
    }
  }
}

export function connectSwitch(handle, switch_id) {
  return function(dispatch) {
    const data = {
      devices: handle,
      switch_handle: switch_id,
    }
    return postJSON('connect_switch', data)
      .then(checkErrInRes)
      .then(res => {
        return Promise.all([
          dispatch(setSnackBarMessage('Peer Group succesfully installed', 'success')),
          dispatch(removeItemFromInstallation()),
          dispatch(removeDevicesFromInstallation())
        ])                        
      })
      .catch(err => {
        if (err.response) {
          return dispatch(setSnackBarMessage('There was a problem connecting the switch', 'error'))
        }
      })
  }
}

export function installScriptApp(payload) {
  return function(dispatch) {
    return postJSON('install_app', payload, CACHENGO_APPS_URL)
      .then(checkErrInRes)
      .then(res => {        
        return Promise.all([
          dispatch(setSnackBarMessage('Installation requested', 'success')),
          dispatch(removeItemFromInstallation()),
          dispatch(removeDevicesFromInstallation()),
          dispatch(setAppInstallModalOpen(false))
        ])           
      })
      .catch(err => {
        if (err.response) {
          return dispatch(setSnackBarMessage('There was a problem requesting the installation', 'error'))
        }
      })
  }
}

// export function connectCluster(cluster_id, isServer, handles) {
//   console.log("connecting cluster")
//   return function(dispatch) {
//     const data = {
//       devices: handles,
//       cluster_handle: cluster_id,
//       is_server: isServer
//     }
//     return postJSON('connect_cluster', data)
//       .then(checkErrInRes)
//       .then(res => {
//         return Promise.all([
//           dispatch(setSnackBarMessage('Cluster succesfully installed', 'success')),
//           dispatch(removeItemFromInstallation()),
//           dispatch(removeDevicesFromInstallation())
//         ])                        
//       })
//       .catch(err => {
//         if (err.response) {
//           console.log(err)
//           return dispatch(setSnackBarMessage('There was a problem connecting the cluster', 'error'))
//         }
//       })
//   }
// }