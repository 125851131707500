/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

 import React, { useState } from 'react';
 import { useHistory } from "react-router-dom";
 import IconButton from '@material-ui/core/IconButton';
 import List from '@material-ui/core/List';
 import ListItem from '@material-ui/core/ListItem';
 import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
 import ListItemText from '@material-ui/core/ListItemText';
 import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
 import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
 import VisibilityIcon from '@material-ui/icons/Visibility';
 import DescriptionIcon from '@material-ui/icons/Description';
 import Box from '@material-ui/core/Box';
 import Grid from '@material-ui/core/Grid';
 import Divider from '@material-ui/core/Divider';
 import Tooltip from "@material-ui/core/Tooltip";
 import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
 import Typography from '@material-ui/core/Typography';
 import ListItemAvatar from "@material-ui/core/ListItemAvatar";
 import Avatar from "@material-ui/core/Avatar";
 import DeleteIcon from "@material-ui/icons/Delete";
 import FolderIcon from "@material-ui/icons/Folder";
 import { uninstallKey } from "../utils/Cachengo";


 
 import ShowSecrets from './ShowSecrets';
 import {removeInstall, uninstallApp } from '../utils/Cachengo'
 
 
 function AllInstalledKeys(props) {
   const [showSecrets, setShowSecrets] = useState([]);
   const history = useHistory()
 

   const uninstallAllKeys = (keys, name) => {
    let keyHandle = ""
    for (let i = 0; i < Object.keys(keys).length; i++) {
      keys[Object.keys(keys)[i]][0].forEach((key) => {
        if (key.name === name) {
          keyHandle = key.handle
          uninstallKey(props.handles,key.handle);
          return
        }
      });
    }
  };

   const handleOpenConfirmationModal = (keys, key) => {
    props.handleOpenConfirmation({
      show: true,
      message:
        "Are you sure you want to permanently remove this key from these devices?",
      onYes: () => {
        uninstallAllKeys(keys, key);

        props.handleOpenConfirmation({
          show: false,
          message: null,
          onYes: function () {},
        });
        props.clearSelectedItems();
      },
    });
  };

   const generate = (keys) => {
    const elements = [];
    const keyNames = [];
    if (Object.keys(keys).length > 0) {
      keys[Object.keys(keys)[0]][0].forEach((key) => {
        keyNames.push(key.name);
      });
    }
    keyNames.forEach((key) => {
      elements.push(
        <ListItem key={key}>
          <ListItemAvatar>
            <Avatar>
              <FolderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={key} />
          <Tooltip title="Disconnect Switch">
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => handleOpenConfirmationModal(keys, key)}
          >
            <DeleteIcon />
          </IconButton>
          </Tooltip>
        </ListItem>
      );
    });

    return elements;
  };
 
   var keys = props.keys;
 
   return (
     <Grid container spacing={4} style={{margin: '10px', padding: '10px'}}>
       <Grid item xs={12} lg={6}>
         <Box variant="outlined">
           {(keys[Object.keys(keys)[0]][0].length != 0) ?
           <List>{generate(keys)}</List>
           :
           <Typography>No keys in common installed on these devices.</Typography>
           }
         </Box>
       </Grid>
       <Divider />
     </Grid>
   );
 }
 
export default AllInstalledKeys;