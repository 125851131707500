/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

 http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import ListItemText from "@material-ui/core/ListItemText";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DevicesIcon from '@material-ui/icons/Devices';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { addEventHandler, removeEventHandler } from '../actions/items';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import CardHeader from '@material-ui/core/CardHeader';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AppsIcon from '@material-ui/icons/Apps';
import AlbumIcon from '@material-ui/icons/Album';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import MemoryIcon from '@material-ui/icons/Memory';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { getOrgKeys, fetchAvailableInstances, installKey, doRent } from '../utils/Cachengo';


const mapStateToProps = (state) => {
    return {
      payInfo: state.payInfo || {},
    };
};

function mapDispatchToProps(dispatch) {
  return {
    removeEventHandler: (key) => dispatch(removeEventHandler(key)),
    addEventHandler: (key, handler) => dispatch(addEventHandler(key, handler)),
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  resources: {
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    minHeight: '12vh',
  },
  heading: {
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'left',
    background: '#eaeded',
    color: 'black',
    margin:0,
    padding:0
  },
  border: {
      boxShadow: "None"
  },
  metricsGrid:{
    [theme.breakpoints.up('sm')]: {
      margin: "10px", 
      padding: "10px"
    },
  },
  card: {
    border: '1px solid #d5dbdb',
  },
  divLeft: {
    display:'inline',
    float: 'left'
  },
  divRight: {
    display:'inline',
    float: 'right',
    justifyContent:'right'
  },
  header: {
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'left',
    background: '#eaeded',
    color: 'black'
  },
  title: {
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'left',
    color: 'black'
  },
  bread:{
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }
}));

function ConnectedNewInstanceForm (props) {
  const tableRef = useRef();
  const [isNewRentModalOpen, setNewRentmodal] = useState(false)
  const [deviceHandles, setDeviceHandles] = useState([]);  
  const classes = useStyles();
  const [osImage, setOsImage] = React.useState(0);
  const [osImageName, setOsImageName] = React.useState("ubuntu-jammy-22.04-arm64-server");
  const [publicKey, setPublicKey] = React.useState('');
  const [instanceType, setInstanceType] = React.useState(0);
  const [keys, setKeys] = React.useState([]);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [instanceName, setInstanceName] = useState('');  
  


  const  handleSubmit = ()=> {
    console.log(instanceName)
    console.log(availableDevices)
    var online_devices = availableDevices.filter(device => device.is_connected === true);
    console.log(online_devices)
    var device_handle = online_devices[0].handle
    
    doRent([device_handle], [instanceName])
        .then(res => {
          console.log(res)
          installKey(res.vm_handles,publicKey)
        })
        .then(() => props.history.push('/my_rentals'))
        .catch(() => props.history.push('/my_rentals'));
  }

  const handleChange = (event) => {
    setPublicKey(event.target.value);
  };

  const handleChangeOS = (event) => {
    setOsImage(event.target.value)
    event.target.value === 0 ? setOsImageName("ubuntu-jammy-22.04-arm64-server") : setOsImageName("")
  }

  const handleChangeInstance = (event) => {
    setInstanceType(event.target.value)
  }
  

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;

    setInstanceName(value)
  }

  const buyer = props.payInfo.buyer || {};
  const canBuy = buyer.can_buy || false; 

  useEffect(() => {
    getKeys()
    getAvailableRentals()

  }, [])

  const clearSelectedItems = () => {
    tableRef.current && tableRef.current.clearSelectedItems()
  }

  const getKeys = () =>{
    getOrgKeys().then(res => {
      if (res && res.success) { 
        // const devices = testData.filter(device => device.is_vm === true);
        setKeys(res.public_keys)
        console.log(res.public_keys)
        // console.log(devices)
      } else {
        setKeys([])
      }
    })
  }

  const getAvailableRentals = () =>{
    fetchAvailableInstances({}).then(res => {
      if (res && res.success) { 
        // const devices = testData.filter(device => device.is_vm === true);
        // setKeys(res.public_keys)
        let newData = res.data
        newData = newData.filter((dev)=> dev.is_connected === true)
        setAvailableDevices(newData)
        // console.log(devices)
      } else {
        // setKeys([])
        console.log('failed')
        setAvailableDevices([])
      }
    })
  }
  
  const toggleRentModal = (rowData) => {
    if ( isNewRentModalOpen ){
      setNewRentmodal( !isNewRentModalOpen )
    } else {
      setNewRentmodal(!isNewRentModalOpen);
      let handles = rowData.map(device => {
        return {handle: device.handle, name: device.name}
      });
      setDeviceHandles(handles)
    }
  }

  const refreshData = () => {
    tableRef.current && tableRef.current.refreshTable()
  }

  const deviceIcon = props => {
    return (
      <DevicesIcon {...props} style={{ margin: '-4px 8px 0 0' }} color='secondary' />
    )
  };
  const columnHead = {
    field: 'name', icon: deviceIcon
  }
  const columns = [
    {
      render: rowData => {
        return (
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='body2' >
                Price: ${rowData.price}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                Cores: {rowData.cores}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                ARCH: {rowData.arch}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                RAM: {parseFloat((rowData.ram / Math.pow(1024, 3)).toFixed(1))} GB
            </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                Location: {rowData.city}, {rowData.province}. {rowData.country}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant='body2' >
                Accelerator: {rowData.accelerator}
              </Typography>
            </Grid>
          </Grid>
        )
      }
    }
  ]

  const shoppingCartIcon = (props) => (
    <ShoppingCartIcon {...props} />
  );

  const actions = [
    {
      icon: shoppingCartIcon,
      tooltip: 'Rent-a-Node',
      onClick: (event, rowData) => {
        toggleRentModal(rowData)
      },
    },
  ]

  return (
    // <div>
    // {canBuy ? 
    // <Grid style={{paddingTop: '20px'}}>
    //     <CustomTable
    //         ref={tableRef}
    //         multiSelect={true}
    //         title='Rent-a-Node'
    //         getData={fetchAvailableRentals}
    //         actions={actions}
    //         columns={columns}
    //         columnHead={columnHead}
    //         errMessage={'Problem fetching rental devices'}
    //     />
    //     <NewRentModal 
    //         show={isNewRentModalOpen}
    //         handleClose={toggleRentModal}
    //         handles={deviceHandles}
    //         clearSelectedItems={clearSelectedItems}
    //         refreshTable={refreshData}
    //     />
    //     </Grid>
    // :
    // <Grid style={{paddingTop: '20px'}}>
    //     <Typography>
    //         To activate node rental capabilities, 
    //         please enter your payment information in the "Balances" section.
    //     </Typography>
    // </Grid> 
    // }   
    // </div>
  <div className={classes.root}>
    {canBuy ?
    <Grid container spacing={1}>
      <Grid item xs={12}>
      <div className={classes.bread}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link color="inherit" component="button" variant="body2" onClick={() => {props.history.push('/')}}>
          Home
        </Link>
        <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/rentnode')}}>
          Rent-a-Node
        </Link>
        <Link color="inherit"  component="button" variant="body2" onClick={() => {props.history.push('/my_rentals')}}>
          Instances
        </Link>
        <Typography color="textPrimary">Launch an instance</Typography>
      </Breadcrumbs>
    </div>
      </Grid>
      <Grid item xs={12}>
      <CardHeader titleTypographyProps={{variant:'h4' }} xs={12} title="Launch an instance" className={classes.title} subheader="Rent-a-Node allows you to create virtual machines, or instances, that run on RNOs (Rent-a-Node Operators). Quickly get started by following the simple steps below."></CardHeader>

      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.resources}>
          <Grid container spacing={3}>
            <CardHeader titleTypographyProps={{variant:'h6' }} xs={12} title="Name" className={classes.header}></CardHeader>
            <TextField
          id="standard-full-width"
          label="Name"
          style={{ margin: 8 }}
          placeholder="My Instance"
          helperText="Give your instance a descriptive name."
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleInputChange}
        />
          </Grid>
        </Paper>
        <div style={{ paddingTop: "10px" }}>
        <Accordion className={classes.heading} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AlbumIcon color="secondary" style={{marginRight: "5px"}}/>
            <Typography className={classes.heading} fontWeight="fontWeightBold">
              OS Image
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{background:'white'}}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">OS Image</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={osImage}
                      label="OS Image"
                      onChange={handleChangeOS}
                    >
                      <MenuItem value={0}>ubuntu-jammy-22.04-arm64-server</MenuItem>
                    </Select>
                  </FormControl>
          </AccordionDetails>
        </Accordion>
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Accordion className={classes.heading} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <MemoryIcon color="secondary" style={{marginRight: "5px"}}/>
            <Typography className={classes.heading} fontWeight="fontWeightBold">
              Instance Type
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{background:'white'}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Instance Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={instanceType}
                  label="Instance Type"
                  onChange={handleChangeInstance}
                >
                  <MenuItem value={0} style={{whiteSpace: 'normal'}}>
                    { availableDevices.length > 0 ?
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span><strong>Venom</strong></span>
                      <span>8 CPU 16 GiB Memory</span>
                      <span>{availableDevices.length} Instances Available</span>
                    </div>
                    : 
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span><strong>No Available Instances</strong></span>
                    </div>
                    }
                  </MenuItem>
                </Select>
              </FormControl>

          </AccordionDetails>
        </Accordion>
      </div>
      {/* <Divider style={{ margin: "10px" }}/> */}
      <div style={{ paddingTop: "10px" }}>
        <Accordion className={classes.heading} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <VpnKeyIcon color="secondary" style={{marginRight: "5px"}}/>
            <Typography className={classes.heading}>
              Public Key
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{background:'white'}}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select a Public Key</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={publicKey}
                  label="Public Key"
                  onChange={handleChange}
                  placeholder="Select a Public Key"
                >
                { keys.map((key) => {
                  return(
                    <MenuItem value={key.handle}>{key.name}</MenuItem>
                  );
                  })
                }
                </Select>
              </FormControl>

          </AccordionDetails>
        </Accordion>
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Accordion className={classes.heading} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            expa
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AppsIcon color="secondary" style={{marginRight: "5px"}}/>
            <Typography className={classes.heading}>
              Summary
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{background:'white'}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ListItemText secondary={osImageName} primary="OS Image" />
              </Grid>
              { availableDevices.length > 0 ?
              <Grid item xs={12}>
                <ListItemText secondary="venom" primary="Virtual Server Type" />
              </Grid>
              :
              <Grid item xs={12}>
                <ListItemText secondary="No available instances" primary="Virtual Server Type" />
              </Grid>
              }
              { availableDevices.length > 0 ?
              <Grid item xs={12}>
                <ListItemText secondary="$0/month" primary="Price" />
              </Grid>
              :
              <Grid item xs={12}>
                <ListItemText secondary="$0/month" primary="Price" />
              </Grid>
              }
              <Grid item xs={12}>
                {
                  availableDevices.length > 0 && instanceName !== "" ? 
                  <Button
                  style={{ height: "fit-content", backgroundColor:'#fc5001', fontWeight:'bold', color:'white'
                  }}
                    variant="contained"
                    onClick={handleSubmit}
                >

                  Launch Instance
                </Button>
                  :
                  <Button
                  style={{ height: "fit-content", backgroundColor:'gray', fontWeight:'bold', color:'white'
                  }}
                    variant="contained"
                    disabled
                >
                  Launch Instance
                </Button>
                }
              </Grid>
            </Grid>

          </AccordionDetails>
        </Accordion>
      </div>
      </Grid>
    </Grid>
        :
    <Grid style={{paddingTop: '20px'}}>
        <Typography>
            To activate node rental capabilities, 
            please enter your payment information in the "Balances" section.
        </Typography>
    </Grid> 
    }  
  </div>
  );
}

const NewInstance = connect(
  mapStateToProps, mapDispatchToProps
)(ConnectedNewInstanceForm);

export default NewInstance;