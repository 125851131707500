/*
 Copyright 2019, Cachengo, Inc.
 Licensed under the Apache License, Version 2.0 (the "License");
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at
 
 http://www.apache.org/licenses/LICENSE-2.0
 
 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an "AS IS" BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
 */

 import React, { Component } from "react";
 import Button from "@material-ui/core/Button";
 import Dialog from "@material-ui/core/Dialog";
 import DialogActions from "@material-ui/core/DialogActions";
 import DialogContent from "@material-ui/core/DialogContent";
 import DialogTitle from "@material-ui/core/DialogTitle";
 
 import List from "@material-ui/core/List";
 import ListItem from "@material-ui/core/ListItem";
 import ListItemText from "@material-ui/core/ListItemText";
 
 import IconButton from "@material-ui/core/IconButton";
 import ListItemAvatar from "@material-ui/core/ListItemAvatar";
 import Avatar from "@material-ui/core/Avatar";
 import DeleteIcon from "@material-ui/icons/Delete";
 import FolderIcon from "@material-ui/icons/Folder";
 
 import { installKey } from "../../utils/Cachengo";
 import Checkbox from '@material-ui/core/Checkbox';

 
 export default class SetInstallKeysModal extends Component {
  
   constructor(props) {
     super(props);
     this.state = {
       modalOpen: false,
       checked: [],
     };
   }
   
 
   handleChange = (event) => {
    if (event.target.checked) {
      this.setState({ checked: [...this.state.checked,event.target.value]})
    } else {
      if (this.state.checked.includes(event.target.value)) {
        let newArr = this.state.checked.filter((handle) => handle !== event.target.value)
        this.setState({ checked: newArr})
      }
    }
  };

   handleOpenConfirmationModal = () => {
     this.props.handleOpenConfirmation({
       show: true,
       message:
         "Are you sure you want to install the selected public keys on the selected device?",
       onYes: () => {
         this.installKeys(this.props.handles, this.state.checked);
 
         this.props.handleOpenConfirmation({
           show: false,
           message: null,
           onYes: function () {},
         });
         this.props.handleClose();
         this.props.clearSelectedItems();
       },
     });
   };
 
   handleDeleteIconClick = () => {
     this.setState({ modalOpen: true });
   };
 
   handleCloseModal = () => {
     this.setState({ modalOpen: false });
   };
 
   installKeys = (handles, keys) => {
     installKey(handles,keys)
   };
 
   generate = (keys) => {
     const elements = [];
    //  const appNames = [];
    //  if (Object.keys(apps).length > 0) {
    //    apps[Object.keys(apps)[0]].forEach((app) => {
    //      appNames.push(app.name);
    //    });
    //  }
  
     keys.forEach((key) => {
       elements.push(
         <ListItem key={key}>
           <ListItemAvatar>
             <Avatar>
               <FolderIcon />
             </Avatar>
           </ListItemAvatar>
           <ListItemText primary={key.name} />
           <Checkbox
            onChange={this.handleChange}
            color="default"
            inputProps={{ 'aria-label': 'checkbox with default color' }}
            value={key.handle}
          />
           {/* <IconButton
             edge="end"
             aria-label="delete"
             onClick={() => this.handleOpenConfirmationModal(apps, app)}
           >
             <DeleteIcon />
           </IconButton> */}
         </ListItem>
       );
     });
 
     return elements;
   };
 
   render() {
     console.log(this.props.apps);
 
     return (
       <Dialog
         onBackdropClick="false"
         open={this.props.show}
         onClose={this.props.handleClose}
         aria-labelledby="newswitch-dialog-title"
         fullWidth
       >
         <DialogTitle id="newswitch-dialog-title">
           Select keys to install on device
         </DialogTitle>
 
         <DialogContent>
           <List>{this.generate(this.props.keys)}</List>
         </DialogContent>
 
         <DialogActions>
           <Button onClick={this.handleOpenConfirmationModal}>Submit</Button>
           <Button onClick={this.props.handleClose}>Close</Button>
         </DialogActions>
       </Dialog>
     );
   }
 }